import { useSelector } from 'react-redux';
import c from 'classnames';

import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import { getHomePageTinyAccountId } from 'store/home';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  LinkButton,
  TextLink,
} from 'components';
import { Typography, TextColor } from '@repo/ui';
import { HomeNextStepProfile } from './steps/HomeNextStepProfile';

import styles from './HomeNextSteps.module.scss';

type NextStepProps = {
  title: string;
  isActive: boolean;
  Status: React.ReactNode;
  clickable?: {
    text: string;
    url: string;
    isExternal?: boolean;
    onClick?: () => Promise<void>;
  };
  inlineLinkPostCompletion?: {
    text: string;
    url: string;
    isExternal?: boolean;
    onClick?: () => Promise<void>;
  };
  description?: string;
  isBlocking?: boolean;
  descriptionBlocking?: string;
  isCompleted?: boolean;
  CompletedContent?: React.ReactNode;
};
export const HomeNextStep = ({
  title,
  isActive,
  Status,
  clickable,
  inlineLinkPostCompletion,
  description,
  isCompleted,
  CompletedContent,
  isBlocking,
  descriptionBlocking,
}: NextStepProps) => {
  return (
    <div className='flex flex-column gap-2'>
      <div className={c(styles.titleRow, isActive && styles.active)}>
        <div className='flex align-items-center gap-2'>
          <Typography variant='heading-m'>{title}</Typography>
          {isCompleted && !!inlineLinkPostCompletion && (
            <TextLink
              label={inlineLinkPostCompletion.text}
              href={inlineLinkPostCompletion.url}
              external={!!inlineLinkPostCompletion.isExternal}
              size={'label'}
              color={TextColor.PURPLE}
              onClick={inlineLinkPostCompletion.onClick}
            />
          )}
        </div>
        {Status}
      </div>
      {isCompleted && !!CompletedContent && CompletedContent}
      {!!description && (
        <div className={styles.forceLineHeight}>
          <Typography variant='label'>{description}</Typography>
        </div>
      )}
      {isBlocking && !!descriptionBlocking && (
        <div className={styles.forceLineHeight}>
          <Typography variant='label'>{descriptionBlocking}</Typography>
        </div>
      )}

      {!!clickable && isActive && (
        <LinkButton
          label={clickable.text}
          href={clickable.url}
          state={{ from: '/' }}
          external={!!clickable.isExternal}
          variant={ButtonVariants.SOLID}
          size={ButtonSizes.M}
          color={ButtonColors.DARK_PURPLE}
          onClick={clickable.onClick}
          width={'full'}
        />
      )}
    </div>
  );
};

export function HomeNextSteps() {
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const { latestKit, getDisplayName, stepsList } =
    useTinyAccountForHome(tinyAccountId);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className='hide-on-mobile'>
          <Typography variant='heading-l'>{`${getDisplayName()} Next Steps`}</Typography>
        </div>
        <div className='hide-on-desktop'>
          <Typography variant='heading-xl'>{`${getDisplayName()} Next Steps`}</Typography>
        </div>
        {!!latestKit?.id && (
          <Typography variant='label'>{`Kit ID: ${latestKit.id}`}</Typography>
        )}
      </div>
      <HomeNextStepProfile />
      {stepsList.map((step, index) => (
        <HomeNextStep {...step} key={`${step}-${index}`} />
      ))}
    </div>
  );
}

export const HomeResultsStatus = ({ text }: { text: string }) => {
  return (
    <div
      className={c(
        styles.status,
        text === 'Ready' ? styles.green : styles.blue,
      )}
    >
      <div className={styles.dot}></div>
      <Typography variant='label'>{text}</Typography>
    </div>
  );
};
