import { useSelector } from 'react-redux';
import { Routes, Route, Link } from 'react-router-dom';
import c from 'classnames';
import { v4 as uuid } from 'uuid';

import {
  CardSlider,
  Button,
  PageContainer,
  ContainerSizes,
  Typography,
} from 'components';
import { getTinyAccounts } from 'store/account';
import { getKits } from 'store/shared';
import { getSortedKitsForTinyAccountId } from 'store/kits';
import TinyAccount from 'types/TinyAccount';

import { KitCard } from './KitCard';
import { MyFamily } from './MyFamily';
import { ActivateKit } from './ActivateKit';
import { NoKitRegistered } from '../Results';
import { ResampleSummaryCard } from 'views/Results/Summary/SummaryCards';

import styles from './Kits.module.scss';

export const KitsRoutes = () => {
  return (
    <Routes>
      <Route path='activate' element={<ActivateKit />} />
      <Route path='' element={<Kits />} />
    </Routes>
  );
};

const TinyAccountKits = ({ tinyAccount }: { tinyAccount: TinyAccount }) => {
  const kits = useSelector(getSortedKitsForTinyAccountId(tinyAccount.id));
  return (
    <div key={tinyAccount.id}>
      {!!kits.length && (
        <h3 className={styles.kitHeader}>
          {`${tinyAccount.first_name}'s kit${kits.length ? 's' : ''}`}
        </h3>
      )}
      <div className={styles.kitCardSliderWrapper}>
        {!!kits.length && (
          <CardSlider
            cards={kits.map(kit => (
              <KitCard
                key={kit.id}
                tinyAccount={tinyAccount}
                kit={kit}
                maxWidthSm
                pointer
              />
            ))}
          />
        )}
      </div>
    </div>
  );
};
export const Kits = () => {
  const tinyAccounts = useSelector(getTinyAccounts);
  const kits = useSelector(getKits);
  return (
    <PageContainer size={ContainerSizes.XL}>
      <div className={c(styles.pageContainer, styles.paddingBottom)}>
        <div className={c(styles.pageHeader, 'p-3')}>
          <Typography variant='heading-xl' className={styles.header}>
            Kits
          </Typography>
        </div>
        {tinyAccounts?.length ? <hr className={styles.hr} /> : null}
        <div className='p-3'>
          <MyFamily
            type='row'
            tinyAccounts={tinyAccounts}
            title='My Family'
            strictDisplayView={true}
          />
        </div>
        {tinyAccounts?.length ? (
          <hr className={c(styles.hr, styles.inverted)} />
        ) : null}

        <div className='p-3'>
          {kits.length > 0 ? (
            <>
              {tinyAccounts?.map(tinyAccount => (
                <TinyAccountKits key={uuid()} tinyAccount={tinyAccount} />
              ))}
            </>
          ) : (
            <div className='mt-4 text-center'>
              <NoKitRegistered withCTA={false} />
            </div>
          )}
          <div className='flex justify-content-center'>
            <ResampleSummaryCard />
          </div>
        </div>
        <div className={styles.fixedBottom}>
          <Link to='/kits/activate'>
            <Button width='full'>Activate kit</Button>
          </Link>
        </div>
      </div>
    </PageContainer>
  );
};
