import { useSelector } from 'react-redux';
import { Routes, Route, Link } from 'react-router-dom';
import c from 'classnames';
import { v4 as uuid } from 'uuid';

import useFeature from 'services/features/useFeature';
import { getTinyAccounts } from 'store/account';
import { getKits } from 'store/shared';

import { Button, PageContainer, ContainerSizes, Typography } from 'components';
import { MyFamily } from './MyFamily';
import { ActivateKit } from './ActivateKit';
import { NoKitRegistered } from '../Results';
import { ResampleSummaryCard } from 'views/Results/Summary/SummaryCards';
import { TinyAccountKits } from './TinyAccountKits/TinyAccountKits';
import { Kits } from './Kits';

import styles from './KitsPage.module.scss';

export const KitsRoutes = () => {
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');

  return (
    <Routes>
      <Route path='activate' element={<ActivateKit />} />
      <Route path='' element={showProfiles ? <KitsPage /> : <Kits />} />
    </Routes>
  );
};

export const KitsPage = () => {
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const tinyAccounts = useSelector(getTinyAccounts);
  const kits = useSelector(getKits);
  return (
    <div className={c(styles.pageContainer, styles.paddingBottom)}>
      <div className='p-3'>
        <Typography variant='heading-xl' className='dark-grey-text'>
          Kits
        </Typography>
      </div>
      {tinyAccounts?.length ? <hr className={styles.hr} /> : null}
      <div className='p-3'>
        <MyFamily
          type='row'
          tinyAccounts={tinyAccounts}
          title='My Family'
          strictDisplayView={!showProfiles}
          withProfile={showProfiles}
        />
      </div>
      {tinyAccounts?.length ? (
        <hr className={c(styles.hr, styles.inverted)} />
      ) : null}

      <PageContainer size={ContainerSizes.MD}>
        <>
          <div className='p-3'>
            {kits.length > 0 ? (
              <div className={styles.accountsContainer}>
                {tinyAccounts?.map(tinyAccount => (
                  <TinyAccountKits key={uuid()} tinyAccount={tinyAccount} />
                ))}
              </div>
            ) : (
              <div className='mt-4 text-center'>
                <NoKitRegistered withCTA={false} />
              </div>
            )}
            <div className='flex justify-content-center'>
              <ResampleSummaryCard />
            </div>
          </div>
          <div className={styles.fixedBottom}>
            <Link to='/kits/activate'>
              <Button width='full'>Activate kit</Button>
            </Link>
          </div>
        </>
      </PageContainer>
    </div>
  );
};
