import { useLocation } from 'react-router-dom';
import { ContainerSizes, PageContainer, TextLink } from 'components';
import { Typography, TextColor } from '@repo/ui';
import TakeNoteSVG from 'assets/images/placeholders/take-note-01.svg?react';

export const NoKitResults = () => {
  const { state } = useLocation();
  return (
    <PageContainer
      size={ContainerSizes.AUTO}
      className='flex flex-column gap-2 text-center'
    >
      <TextLink
        label='Home'
        href={'/'}
        iconNameLeft='chevronBack'
        color={TextColor.DARK}
        state={state}
      />
      <div className='mt-4'>
        <TakeNoteSVG />
      </div>
      <Typography variant={'heading-l'}>No results yet</Typography>
      <Typography variant={'body-s'} serif className='grey-text'>
        Go to the Home page to see your kit&apos;s progress.
      </Typography>
    </PageContainer>
  );
};
