import c from 'classnames';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  LinkButton,
  TextLink,
  Typography,
} from 'components';
import { useLocation } from 'react-router-dom';

import styles from './DiveDeeperSummaryCard.module.scss';

export interface DDSCardProps {
  title: string;
  description?: string;
  link?: string;
  linkText?: string;
  className?: string;
  children?: React.ReactNode;
}

export const DiveDeeperSummaryCard: React.FC<DDSCardProps> = ({
  title,
  description,
  link,
  linkText,
  className,
  children,
}) => {
  const { pathname } = useLocation();
  return (
    <div className={c(styles.summaryCardWrapper, className)}>
      <div className={styles.mainContent}>
        <Typography variant={'heading-l'}>{title}</Typography>
        {!!description && (
          <Typography variant={'body-s'} serif className={styles.textGrey}>
            {description}
          </Typography>
        )}
        {children}
      </div>
      {!!link && !!linkText && (
        <TextLink
          label={linkText}
          iconNameRight='chevronForward'
          href={link}
          size='label'
        />
      )}
    </div>
  );
};
