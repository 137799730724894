import c from 'classnames';

import { Icon, Typography } from '@repo/ui';

import styles from './EvaluationGroupsSummary.module.scss';

export const getEvaluationIconFromColor = (color: string) => {
  switch (color) {
    case 'green':
      return 'checkVerifiedFilled';
    case 'orange':
      return 'alertCircleFilled';
    case 'red':
      return 'alertTriangleFilled';
  }
  return 'checkFilled';
};

export const getEvaluationTextFromColor = (color: string) => {
  switch (color) {
    case 'green':
      return 'Great results';
    case 'orange':
      return 'Needs improvement';
    case 'red':
      return 'Needs support';
  }
  return 'Normal results';
};

type PieGraphProps = {
  greenPercentage: number;
  greyPercentage: number;
  orangePercentage: number;
  redPercentage: number;
};

const PieGraph = ({
  greenPercentage,
  greyPercentage,
  orangePercentage,
  redPercentage,
}: PieGraphProps) => {
  return (
    <div
      className={styles.pie}
      style={{
        background: `conic-gradient(var(--warm-grey-500) 0.00% ${greyPercentage}%, var(--green-300) ${greyPercentage}% ${
          greyPercentage + greenPercentage
        }%, var(--orange-300) ${greyPercentage + greenPercentage}% ${
          greyPercentage + greenPercentage + orangePercentage
        }%, var(--red-300) ${
          greyPercentage + greenPercentage + orangePercentage
        }%)`,
      }}
    ></div>
  );
};

type EvaluationGroupsSummaryProps = {
  greenCount: number;
  greyCount: number;
  orangeCount: number;
  redCount: number;
  variant?: 'simple' | 'bars' | 'graph' | 'both';
};
export const EvaluationGroupsSummary = ({
  greenCount,
  greyCount,
  orangeCount,
  redCount,
  variant = 'simple',
}: EvaluationGroupsSummaryProps) => {
  const total = greenCount + greyCount + orangeCount + redCount;
  const greenPercentage = (greenCount / total) * 100;
  const greyPercentage = (greyCount / total) * 100;
  const orangePercentage = (orangeCount / total) * 100;
  const redPercentage = (redCount / total) * 100;

  if (total === 0) return <></>;
  return (
    <div className={styles.outerContainer}>
      {(variant === 'graph' || variant === 'both') && (
        <PieGraph
          greenPercentage={greenPercentage}
          greyPercentage={greyPercentage}
          orangePercentage={orangePercentage}
          redPercentage={redPercentage}
        />
      )}
      <div className={styles.groupContainer}>
        <div className={styles.groupRow}>
          <Icon
            name={getEvaluationIconFromColor('green')}
            className={c(styles.green, 'my-auto')}
            size='s'
          />
          <div className={styles.evaluationLabel}>
            <Typography variant='label'>Great results</Typography>
          </div>
          {(variant === 'bars' || variant === 'both') && (
            <div className={styles.barContainer}>
              <div
                className={c(styles.bar, styles.green)}
                style={{ flexBasis: greenPercentage + '%' }}
              />
            </div>
          )}
          <div className={styles.countLabel}>
            <Typography variant='label'>{`${greenCount}/${total}`}</Typography>
          </div>
        </div>

        <div className={styles.groupRow}>
          <Icon
            name={getEvaluationIconFromColor('grey')}
            className={c(styles.grey, 'my-auto')}
            size='s'
          />
          <div className={styles.evaluationLabel}>
            <Typography variant='label'>Normal results</Typography>
          </div>
          {(variant === 'bars' || variant === 'both') && (
            <div className={styles.barContainer}>
              <div
                className={c(styles.bar, styles.grey)}
                style={{ flexBasis: greyPercentage + '%' }}
              />
            </div>
          )}
          <div className={styles.countLabel}>
            <Typography variant='label'>{`${greyCount}/${total}`}</Typography>
          </div>
        </div>

        <div className={styles.groupRow}>
          <Icon
            name={getEvaluationIconFromColor('orange')}
            className={c(styles.orange, 'my-auto')}
            size='s'
          />
          <div className={styles.evaluationLabel}>
            <Typography variant='label'>Needs Improving</Typography>
          </div>
          {(variant === 'bars' || variant === 'both') && (
            <div className={styles.barContainer}>
              <div
                className={c(styles.bar, styles.orange)}
                style={{ flexBasis: orangePercentage + '%' }}
              />
            </div>
          )}
          <div className={styles.countLabel}>
            <Typography variant='label'>{`${orangeCount}/${total}`}</Typography>
          </div>
        </div>

        <div className={styles.groupRow}>
          <Icon
            name={getEvaluationIconFromColor('red')}
            className={c(styles.red, 'my-auto')}
            size='s'
          />
          <div className={styles.evaluationLabel}>
            <Typography variant='label'>Needs support</Typography>
          </div>
          {(variant === 'bars' || variant === 'both') && (
            <div className={styles.barContainer}>
              <div
                className={c(styles.bar, styles.red)}
                style={{ flexBasis: redPercentage + '%' }}
              />
            </div>
          )}
          <div className={styles.countLabel}>
            <Typography variant='label'>{`${redCount}/${total}`}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
