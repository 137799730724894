import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import useFeature from 'services/features/useFeature';
import { colorForTinyAccount } from 'helpers';
import { getTinyAccountsOrderedByLatestKitResults } from '../../store/account';
import { getHomePageTinyAccountId } from 'store/home';
import TinyAccount from 'types/TinyAccount';

import { NewFamilyMemberModal } from 'components';
import { Icon, Typography } from '@repo/ui';
import styles from './TinyAccountSelector.module.scss';

type FadingTextProps = {
  text: string;
  onClick?: () => void;
};
const FadingText: React.FC<FadingTextProps> = ({ text, onClick }) => {
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //if the text does overflow we add a class to fade the text instead of ellipsis
    if (textRef.current) {
      if (textRef.current.scrollWidth > textRef.current.clientWidth) {
        textRef.current.classList.add('fadeText');
      } else {
        textRef.current.classList.remove('fadeText');
      }
    }
  }, [text]);

  return (
    <div className={styles.avatarName} ref={textRef} onClick={onClick}>
      <Typography variant='heading-s'>{text}</Typography>
    </div>
  );
};
const AddTinyAccountButton = ({ onClick }: { onClick: () => void }) => (
  <div className={c(styles.avatarButton)} onClick={onClick}>
    <div
      data-testid='addNew'
      className={c(styles.avatarBubble, styles.bubbleAdd, 'btn')}
    >
      <Icon name='plus' size='m' />
    </div>
    <Typography variant='heading-s' className={styles.avatarName}>
      Add
    </Typography>
  </div>
);
export const TinyAccountSelector = ({
  onSelection,
  currentTinyAccountId,
  withProfile = false,
  hideAdd = false,
}: {
  onSelection?: (tinyAccount?: Partial<TinyAccount>) => void;
  currentTinyAccountId?: string | null;
  withProfile?: boolean;
  hideAdd?: boolean;
}) => {
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );
  const [showModal, setShowModal] = useState(false);
  let carouselRef: Carousel | null = null;
  useEffect(() => {
    if (orderedTinyAccounts.length > 0 && currentTinyAccountId) {
      const index = orderedTinyAccounts.findIndex(
        ta => ta.id === currentTinyAccountId,
      );
      if (index >= 0 && !!carouselRef) {
        carouselRef?.goToSlide(index);
      }
    }
  }, [carouselRef, currentTinyAccountId, orderedTinyAccounts]);
  const handleSelection = (tinyAccount: TinyAccount) => {
    //call the function passed down on props with the selected family member (tinyAccount)
    if (onSelection && typeof onSelection === 'function') {
      onSelection(tinyAccount);
    }
  };

  return orderedTinyAccounts && orderedTinyAccounts.length === 0 ? null : (
    <>
      <NewFamilyMemberModal
        toggle={() => setShowModal(prev => !prev)}
        showModal={showModal}
        //after a new family member is created we select it (same as clicking on it afterwards)
        onFinishCallback={onSelection}
        withProfile={showProfiles && withProfile}
      />

      <Carousel
        ref={e => (carouselRef = e)}
        responsive={{
          desktop: {
            breakpoint: { max: 10000, min: 950 },
            items: 2,
            slidesToSlide: 2,
          },
          mobile: {
            breakpoint: { max: 949, min: 0 },
            items: 3,
            slidesToSlide: 2,
          },
        }}
        className={styles.carousel}
        minimumTouchDrag={48}
        focusOnSelect={true}
        centerMode
      >
        {!hideAdd && orderedTinyAccounts?.length >= 4 && (
          <AddTinyAccountButton onClick={() => setShowModal(true)} />
        )}
        {orderedTinyAccounts?.map(ta => (
          <div
            key={ta.id}
            data-testid='familyAvatar'
            className={c(styles.avatarButton)}
          >
            <div
              className={c(
                styles.avatarBubble,
                currentTinyAccountId === ta.id ? styles.active : '',
              )}
              style={{
                backgroundColor: colorForTinyAccount(
                  orderedTinyAccounts,
                  ta.id,
                ),
              }}
              onClick={() => handleSelection(ta)}
            >
              <Typography variant='heading-xl'>
                {ta.first_name.substr(0, 1)}
              </Typography>
            </div>
            <FadingText
              text={ta.first_name}
              onClick={() => handleSelection(ta)}
            />
          </div>
        ))}

        {!hideAdd && orderedTinyAccounts.length < 4 && (
          <AddTinyAccountButton onClick={() => setShowModal(true)} />
        )}
      </Carousel>
    </>
  );
};
