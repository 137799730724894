import { useState, useEffect, ChangeEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import c from 'classnames';

import useUpdateTinyAccount from 'services/account/useUpdateTinyAccount';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getTinyAccounts } from 'store/account';
import TinyAccount from 'types/TinyAccount';

import {
  ButtonColors,
  ButtonSizes,
  Card,
  DesignButton,
  NewFamilyMemberModal,
} from 'components';
import { Typography } from '@repo/ui';
import styles from './PickBirthMother.module.scss';

type SelectBirthMotherFormProps = {
  selectBirthMother: string;
};
export const PickBirthMother = () => {
  const [selectableTinyAccounts, setSelectableTinyAccounts] = useState<
    TinyAccount[]
  >([]);
  const [showModal, setShowModal] = useState(false);
  const tinyAccountMutation = useUpdateTinyAccount();
  const { currentTinyAccount, currentTinyAccountId } =
    useCurrentKitAndTinyAccount();
  const tinyAccounts = useSelector(getTinyAccounts);
  const {
    handleSubmit,
    trigger,
    setValue,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SelectBirthMotherFormProps>();

  useEffect(() => {
    setSelectableTinyAccounts(
      tinyAccounts
        ? tinyAccounts?.filter(ta => ta.id !== currentTinyAccountId)
        : [],
    );
  }, [currentTinyAccountId, tinyAccounts]);

  const submitSelectBirthMotherForm = async (
    formData: SelectBirthMotherFormProps,
  ) => {
    if (!currentTinyAccountId) return;
    try {
      tinyAccountMutation.mutate({
        body: { birth_mother_id: formData?.selectBirthMother },
        tinyAccountId: currentTinyAccountId,
      });

      toast.success('Birth mother has been added');
    } catch (error) {
      toast.error(
        'There was an error saving the Birth Mother. Please try again',
      );
    }
  };

  //add new member modal
  const toggleModal = () => setShowModal(prev => !prev);
  const onSelectedMember: ChangeEventHandler<HTMLSelectElement> = e => {
    if (e?.target?.value === 'addFamilyMember') {
      setShowModal(true);
      setValue('selectBirthMother', '');
    } else {
      setValue('selectBirthMother', e.target?.value);
    }
    trigger('selectBirthMother');
  };

  const setNewMemberAsSelected = async (tinyAccount?: Partial<TinyAccount>) => {
    if (!tinyAccount?.id) return;
    setValue('selectBirthMother', tinyAccount.id);
    trigger('selectBirthMother');
  };
  const submitBirthmother = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(submitSelectBirthMotherForm)();
  };
  if (!currentTinyAccount) return null;
  return (
    <Card variant='wrapper' className='p-3'>
      <NewFamilyMemberModal
        toggle={toggleModal}
        showModal={showModal}
        //after a new family member is created we set the new tiny account as the selected birth mother
        onFinishCallback={setNewMemberAsSelected}
      />
      <form className={c(styles.wrapper)} onSubmit={submitBirthmother}>
        <div className='flex flex-column gap-3'>
          <div className='flex flex-column gap-2'>
            <Typography variant='heading-s'>{`Who gave birth to ${currentTinyAccount.first_name}?`}</Typography>

            <Controller
              name='selectBirthMother'
              control={control}
              defaultValue=''
              rules={{ required: 'Required. Choose one' }}
              render={({ field }) => (
                <select {...field} onChange={onSelectedMember}>
                  <option value=''>Select a family member</option>
                  {selectableTinyAccounts?.map(tinyAccount => (
                    <option key={tinyAccount.id} value={tinyAccount.id}>
                      {tinyAccount.first_name}
                    </option>
                  ))}
                  <option value='addFamilyMember'>+ Another person</option>
                </select>
              )}
            />

            {errors.selectBirthMother && (
              <div className={styles.error}>
                {errors.selectBirthMother.message}
              </div>
            )}
          </div>
          <div>
            <DesignButton
              label='Save'
              type='submit'
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
              size={ButtonSizes.S}
              color={ButtonColors.PURPLE}
            />
          </div>
        </div>
      </form>
    </Card>
  );
};
