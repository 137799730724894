import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import c from 'classnames';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { getShouldDisplaySeeding, stringToUrl } from 'helpers';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import useSubMetricEvaluations from 'services/insights/useSubMetricEvaluations';
import useKitResults from 'services/results/useKitResults';
import usePersonalizedNote from 'services/general/personalizedNote';
import useFeature from 'services/features/useFeature';
import { InsightEvaluationSection, InsightMetric } from 'types/Insights';

import { ContainerSizes, PageContainer, TextLink } from 'components';
import { Typography, TextColor } from '@repo/ui';
import { ResultsOverviewModal } from '../ResultsOverviewModal/ResultsOverviewModal';
import {
  WytuSummaryCard,
  ResultsOverviewSummaryCard,
  MicrobiomeSummaryCard,
  ConditionsSummaryCard,
  SeedingSummaryCard,
  EvaluationSummaryCard,
  EvaluationsAtAGlanceSummaryCard,
  GutTypeSummaryCard,
  CstSummaryCard,
} from './SummaryCards';
import MicrobiomeScoreSummaryCard from 'views/Results/Summary/SummaryCards/MicrobiomeScoreSummaryCard/MicrobiomeScoreSummaryCard';
import VersionPill from 'components/VersionPill/VersionPill';

import styles from './Summary.module.scss';

const getEvaluationGroupsCount = (metrics: InsightMetric[]) => {
  const greenSubMetrics = metrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'green',
  );
  const greySubMetrics = metrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'grey',
  );
  const orangeSubMetrics = metrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'orange',
  );
  const redSubMetrics = metrics.filter(
    (subMetric: InsightMetric) => subMetric.evaluation_color === 'red',
  );
  return {
    greenCount: greenSubMetrics.length,
    greyCount: greySubMetrics.length,
    orangeCount: orangeSubMetrics.length,
    redCount: redSubMetrics.length,
  };
};

export type InsightSectionWithEvaluationGroupCounts =
  InsightEvaluationSection & {
    great: number;
    okay: number;
    support: number;
  };

const EvaluationsSummaryCards = () => {
  const { data: metrics } = useSubMetricEvaluations();
  const { pathname } = useLocation();
  const subMetricsByCategory: { [key: string]: InsightMetric[] } = useMemo(
    () =>
      metrics.reduce(
        (acc: { [key: string]: InsightMetric[] }, metric: InsightMetric) => {
          const categoryKey =
            metric?.gut_vaginal_health?.key_insights_category
              ?.key_insights_sections?.priority;
          if (categoryKey && acc[categoryKey]) {
            acc[categoryKey]?.push(metric);
          } else if (categoryKey) {
            acc[categoryKey] = [metric];
          }
          return acc;
        },
        {},
      ),
    [metrics],
  );

  return (
    <>
      {Object.keys(subMetricsByCategory)
        .sort((a: string, b: string) => (a < b ? 1 : -1))
        .map((key: string) => {
          const groupSubMetrics = subMetricsByCategory[key];
          if (!groupSubMetrics?.length) return <></>;
          const categoryTitle =
            groupSubMetrics[0]?.gut_vaginal_health?.key_insights_category
              ?.key_insights_sections?.display_title ?? '';
          const { greenCount, greyCount, orangeCount, redCount } =
            getEvaluationGroupsCount(groupSubMetrics);
          return (
            <EvaluationSummaryCard
              key={categoryTitle}
              title={categoryTitle}
              link={`${pathname}/insights/${stringToUrl(categoryTitle)}`}
              evaluationCounts={{
                great: greenCount,
                okay: greyCount,
                improve: orangeCount,
                support: redCount,
              }}
            />
          );
        })}
    </>
  );
};

const Summary = () => {
  useKitResults();
  const { currentKitId, currentKit } = useCurrentKitAndTinyAccount();
  const { data: expertReviewNote } = usePersonalizedNote();
  const navigate = useNavigate();
  const { isFeatureEnabled: showWYTU } = useFeature('what_you_told_us');
  const { isFeatureEnabled: showPopScoreChart } = useFeature(
    'population_score_chart',
  );
  const currentKitTypeIsStool = currentKit?.sampling_type === 'stool';
  const currentKitTypeIsVaginal = currentKit?.sampling_type === 'vaginal';
  const displaySeeding = useMemo(
    () => (currentKit ? getShouldDisplaySeeding(currentKit) : false),
    [currentKit],
  );
  const diveDeeperCardCount =
    3 +
    (showWYTU ? 1 : 0) +
    (displaySeeding ? 1 : 0) +
    (currentKitTypeIsStool &&
    currentKit?.age_at_sampling &&
    currentKit.age_at_sampling <= 365
      ? 1
      : 0) +
    (currentKitTypeIsVaginal ? 1 : 0);

  return (
    <>
      <ResultsOverviewModal
        published={expertReviewNote?.status === 'published'}
      />
      <PageContainer size={ContainerSizes.XL}>
        <div className={styles.resultsGrid}>
          <div className={c(styles.resultsGridInner, styles.twoColumns)}>
            {showPopScoreChart && <MicrobiomeScoreSummaryCard />}

            {expertReviewNote?.status === 'published' && (
              <ResultsOverviewSummaryCard />
            )}
            <EvaluationsAtAGlanceSummaryCard />
          </div>
          <div className={styles.resultsSection}>
            <div className={styles.headerFlex}>
              <Typography variant='heading-l'>Results by category</Typography>
            </div>
            <div
              className={c(
                styles.resultsGridInner,
                styles.twoColumns,
                styles.smallRowHeight,
              )}
            >
              <EvaluationsSummaryCards />
            </div>
          </div>
          <div className={styles.resultsSection}>
            <div
              className={styles.actionPlanCard}
              onClick={() => navigate(`/action-plan/${currentKitId}`)}
            >
              <Typography variant='heading-s'>
                View your full Action Plan
              </Typography>
              <TextLink
                label='Take Action'
                iconNameRight='chevronForward'
                color={TextColor.PURPLE}
              />
            </div>
          </div>
          <div className={styles.resultsSection}>
            <Typography variant='heading-l'>Dive deeper</Typography>
            <div className={styles.desktop}>
              <Carousel
                responsive={{
                  4: {
                    breakpoint: { max: 3000, min: 1400 },
                    items: 4,
                    partialVisibilityGutter: 30,
                  },
                  3: {
                    breakpoint: { max: 1400, min: 1050 },
                    items: 3,
                    partialVisibilityGutter: 30,
                  },
                  2: {
                    breakpoint: { max: 1050, min: 768 },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                }}
                className={styles.carousel}
                removeArrowOnDeviceType={['3', '4'].filter(
                  i => Number(i) >= diveDeeperCardCount,
                )}
                partialVisible
              >
                <MicrobiomeSummaryCard />
                {showWYTU && currentKitId && <WytuSummaryCard />}
                <ConditionsSummaryCard />
                {currentKitTypeIsStool && <GutTypeSummaryCard />}
                {currentKitTypeIsVaginal && <CstSummaryCard />}
                {!!displaySeeding && <SeedingSummaryCard />}
              </Carousel>
            </div>
            <div className={styles.mobile}>
              <div className={c(styles.resultsGridInner, styles.diveDeeper)}>
                <MicrobiomeSummaryCard />
                {showWYTU && currentKitId && <WytuSummaryCard />}
                <ConditionsSummaryCard />
                {currentKitTypeIsStool && <GutTypeSummaryCard />}
                {currentKitTypeIsVaginal && <CstSummaryCard />}
                {!!displaySeeding && <SeedingSummaryCard />}
              </div>
            </div>
          </div>{' '}
          {currentKit?.sci_initial_version && (
            <div className='flex gap-2'>
              <Typography variant='label' className='my-auto'>
                System version
              </Typography>
              <VersionPill version={currentKit.sci_version ?? null} />
            </div>
          )}
        </div>
      </PageContainer>
    </>
  );
};

export default Summary;
