import { useNavigate } from 'react-router-dom';
import { Validate, Controller, useForm } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { differenceInCalendarDays, differenceInCalendarYears } from 'date-fns';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import useFeature from 'services/features/useFeature';
import useCreateTinyAccount from 'services/account/useCreateTinyAccount';
import TinyAccount from 'types/TinyAccount';

import {
  ButtonColors,
  ButtonSizes,
  DesignButton,
  THRadioButton,
} from 'components';
import { Typography, THField } from '@repo/ui';

import Select from 'components/Form/Select/Select';

import styles from './NewFamilyMemberModal.module.scss';

const monthOptions = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

type NewFamilyMemberFormValues = {
  firstName: string;
  lastName: string;
  sex: string;
  day: number | null;
  month: number | null;
  year: number | null;
  birthdate: string; //virtual field
};
type NewFamilyMemberModalProps = {
  toggle: () => void;
  showModal?: boolean;
  onFinishCallback?: (tinyAccount?: Partial<TinyAccount>) => void;
  withProfile?: boolean;
};

const NewFamilyMemberModal = ({
  toggle,
  showModal = false,
  withProfile = false,
  onFinishCallback,
}: NewFamilyMemberModalProps) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<NewFamilyMemberFormValues>({
    mode: 'onTouched',
  });
  const navigate = useNavigate();
  const { isFeatureEnabled: isHomeScreenEnabled } = useFeature('home_screen');
  const tinyAccountMutation = useCreateTinyAccount();
  const CURRENT_YEAR = new Date().getFullYear();

  const firstNameFormField = register('firstName', {
    required: {
      value: true,
      message: 'This field is required.',
    },
  });
  const lastNameFormField = register('lastName', {
    required: {
      value: true,
      message: 'This field is required.',
    },
  });
  const sexFormField = register('sex', {
    required: true,
  });

  const calculateBirthdate = () => {
    const day = watch('day');
    const month = watch('month');
    const year = watch('year');

    if (day && month && year) {
      const birthdate = `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`;
      setValue('birthdate', birthdate, { shouldValidate: true });
    }
  };

  const validateBirthdate: Validate<string, NewFamilyMemberFormValues> = (
    birthdate = '',
  ) => {
    const errorMessage = 'Invalid birthdate';
    const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(birthdate);
    if (!isValidDate) {
      return errorMessage;
    }

    const [year, month, day] = birthdate.split('-').map(Number);
    if (!year || !month || !day) return errorMessage;

    const date = new Date(year, month - 1, day);
    if (
      date >= new Date() ||
      date.getFullYear() !== year ||
      date.getMonth() + 1 !== month ||
      date.getDate() !== day
    ) {
      return errorMessage;
    }
    return true;
  };

  const handleDayChange = (value: number | null) => {
    setValue('day', value, { shouldValidate: true });
    calculateBirthdate();
  };
  const handleMonthChange = (value: number | null) => {
    setValue('month', value, { shouldValidate: true });
    calculateBirthdate();
  };
  const handleYearChange = (value: number | null) => {
    setValue('year', value, { shouldValidate: true });
    calculateBirthdate();
  };

  const submitTinyAccountRegistrationForm = async (
    formData: NewFamilyMemberFormValues,
  ) => {
    const birthdate = new Date(formData.birthdate.replace(/-/g, '/'));

    //difference is based on difference from current date and birthdate
    //if the number is negative then that would mean that the user input birthdate in the future
    const yearsOld = differenceInCalendarYears(new Date(), birthdate);
    const daysOld = differenceInCalendarDays(new Date(), birthdate);

    try {
      //if the number is negative then that would mean that the user input birthdate in the future
      if (yearsOld < 0 || daysOld < 0)
        throw new Error('Birthdate cannot be in the future');
      if (birthdate.toString() === 'Invalid Date' || yearsOld > 102)
        throw new Error('Must have been born after 1920');

      const tinyAccountId = uuidv4();
      const newTinyAccount: Partial<TinyAccount> = {
        id: tinyAccountId,
        first_name: formData.firstName?.trim(),
        last_name: formData.lastName?.trim(),
        birthdate: formData.birthdate,
        sex: formData.sex,
      };
      try {
        await tinyAccountMutation.mutateAsync(newTinyAccount);
        if (onFinishCallback) {
          onFinishCallback(newTinyAccount);
        }
        toggle();
        if (withProfile) {
          navigate(
            `/profile?account=${tinyAccountId}`,
            isHomeScreenEnabled
              ? {
                  state: { from: `/`, tinyAccountId },
                }
              : {},
          );
        } else {
          toast.success('Family member created');
        }
      } catch (err) {
        const error = err as Error;
        if (error.name === 'TinyAccountAlreadyExists') {
          toggle();
          toast.error(error.message);
        } else {
          toast.error('Something went wrong, please try again');
        }
      }
    } catch (error) {
      toast.error('Something went wrong, please try again');
      if (error instanceof Error) {
        setError('birthdate', { type: 'age', message: error.message });
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        fullscreen='md'
        className={styles.modalContainer}
      >
        <ModalHeader toggle={toggle} className={styles.modalHeader}>
          <Typography variant='heading-s'>Add a family member</Typography>
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <form onSubmit={handleSubmit(submitTinyAccountRegistrationForm)}>
            <div className='items-start flex'>
              <THField
                type='text'
                label='First name'
                classes='half'
                errors={errors}
                {...firstNameFormField}
              />
              <THField
                type='text'
                label='Last name'
                classes='half'
                errors={errors}
                {...lastNameFormField}
              />
            </div>
            <div className='mb-1'>
              <Typography variant='heading-s' className='mb-1'>
                Date of birth
              </Typography>
            </div>
            <div className={styles.birthdateContainer}>
              <div>
                <label htmlFor='month'>Month</label>
                <Controller
                  name='month'
                  control={control}
                  rules={{ required: 'This field is required' }}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <Select
                        options={monthOptions}
                        value={value}
                        name={name}
                        placeholder='Select month'
                        enableSearch
                        onChange={selectedOption =>
                          handleMonthChange(
                            selectedOption ? (selectedOption as number) : null,
                          )
                        }
                      />
                    );
                  }}
                />
                {errors.month && <span>This field is required</span>}
              </div>
              <div>
                <label htmlFor='day'>Day</label>
                <Controller
                  name='day'
                  control={control}
                  rules={{ required: 'This field is required' }}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <Select
                        options={Array.from({ length: 31 }, (_, i) => ({
                          value: i + 1,
                          label: `${i + 1}`,
                        }))}
                        name={name}
                        value={value}
                        placeholder='Select day'
                        enableSearch
                        onChange={selectedOption =>
                          handleDayChange(
                            selectedOption ? (selectedOption as number) : null,
                          )
                        }
                      />
                    );
                  }}
                />
                {errors.day && <span>This field is required</span>}
              </div>
              <div>
                <label htmlFor='year'>Year</label>
                <Controller
                  name='year'
                  control={control}
                  rules={{ required: 'This field is required' }}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <Select
                        options={Array.from({ length: 100 }, (_, i) => ({
                          value: CURRENT_YEAR - i,
                          label: `${CURRENT_YEAR - i}`,
                        }))}
                        name={name}
                        value={value}
                        placeholder='Select year'
                        enableSearch
                        onChange={selectedOption =>
                          handleYearChange(
                            selectedOption ? (selectedOption as number) : null,
                          )
                        }
                      />
                    );
                  }}
                />
                {errors.year && <span>This field is required</span>}
              </div>
            </div>
            <input
              {...register('birthdate', { validate: validateBirthdate })}
              readOnly
              className='hidden'
            />
            {errors.birthdate && (
              <span className={styles.formError}>
                {errors.birthdate.message}
              </span>
            )}
            <div className='mt-4'>
              <Typography variant='heading-s'>Sex</Typography>
              <div className={styles.radioBox}>
                <THRadioButton label='Female' value='F' {...sexFormField} />
                <THRadioButton label='Male' value='M' {...sexFormField} />
                <THRadioButton label='Other' value='O' {...sexFormField} />
              </div>
              {errors.sex?.type === 'required' && (
                <div className={styles.formError}>Required. Select one</div>
              )}
            </div>
            <DesignButton
              label='Add member'
              type='submit'
              color={ButtonColors.PURPLE}
              size={ButtonSizes.M}
              disabled={!isValid}
              loading={isSubmitting}
              width={'full'}
            />
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};
export default NewFamilyMemberModal;
