import { useEffect, useRef } from 'react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';

import useFeature from 'services/features/useFeature';
import { getSubMetricDetailQuery } from 'services/insights/useSubMetricDetail';
import { getTaxaOverTimeQuery } from 'services/insights/useTaxaOverTime';
import { getActionPlanItemsQuery } from 'services/actionPlan/actionPlanData';

import { PageHeader, ContainerSizes, PageContainer } from 'components';
import { ActionPlanGroup } from 'views/ActionPlan/ActionPlanGroup/ActionPlanGroup';
import { SubMetricData } from '../SubMetricData/SubMetricData';
import {
  getActionsForSubMetric,
  InsightBreadcrumbs,
  RelatedActions,
} from './SubMetricDetail';

import styles from './SubMetricDetail.module.scss';

export const MaturationIndexDetail = () => {
  //TODO: Implement MaturationIndexDetail component with the new design and logic,
  //for now it is replicating SubMetricDetail component
  const { kitId, subMetricId } = useParams<{
    kitId: string;
    subMetricId: string;
  }>();
  const { state } = useLocation();
  const [{ data: subMetric }, { data: allActions }] = useSuspenseQueries({
    queries: [
      getSubMetricDetailQuery(kitId, subMetricId),
      getActionPlanItemsQuery(kitId),
      getTaxaOverTimeQuery(kitId, parseInt(subMetricId ?? '')),
    ],
  });
  const { isFeatureEnabled } = useFeature('ap_group_by_outcome');
  const startAPRef = useRef(null);
  const actionItems = getActionsForSubMetric(allActions, subMetricId);

  useEffect(() => {
    if (state?.scrollTo === 'actions') {
      if (startAPRef.current) {
        const offset = (startAPRef.current as HTMLElement).offsetTop - 10;
        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }
  }, [state]);

  return (
    <PageContainer size={ContainerSizes.LG}>
      <InsightBreadcrumbs subMetric={subMetric} />
      <PageHeader
        title={subMetric?.name}
        beta={subMetric?.gut_vaginal_health?.key_insights_category?.beta}
      />
      <div className={styles.sectionsContainer}>
        <main>
          <SubMetricData
            metric={subMetric}
            relatedActionsQty={actionItems?.length ?? 0}
            refAP={startAPRef}
          />
        </main>
        <section id='actions' ref={startAPRef}>
          {isFeatureEnabled ? (
            <RelatedActions actions={actionItems} />
          ) : (
            <ActionPlanGroup actionPlanItems={actionItems} withNavigation />
          )}
        </section>
      </div>
    </PageContainer>
  );
};
