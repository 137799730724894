import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import c from 'classnames';
import { uniqBy } from 'lodash-es';

import { LINKS } from 'assets';
import { getFirstParagraph } from 'helpers';
import useFeature from 'services/features/useFeature';
import {
  InsightMetric,
  BacteriumType,
  ReportTaxaCategories,
  GutVaginalHealthStrainKits,
} from 'types/Insights';
import { FeedbackPageType } from 'types/Feedback';

import {
  Modal,
  Card,
  Bacterium,
  ExpandableList,
  AssociationsList,
  EvidenceRating,
  FeedbackWidget,
  Markdown,
  DesignButton,
  ButtonColors,
  ButtonVariants,
  LinkButton,
  ButtonSizes,
  Accordion,
  TextLink,
  FAQ,
} from 'components';
import { Icon, Typography } from '@repo/ui';
import SubMetricGraphs from '../SubMetricGraphs/SubMetricGraphs';

import styles from './SubMetricData.module.scss';

const bacteriumFromReport = (
  report: ReportTaxaCategories,
  isVaginal: boolean,
) => {
  return {
    id: report.taxonomy_results?.id,
    rtc_id: report.id,
    taxonomy_name: report.taxonomy_name,
    classified_relative_abundance:
      report.taxonomy_results?.classified_relative_abundance,
    associations_list: report.associations_list,
    about: isVaginal ? report.about_vaginal : report.about_gut,
    category: isVaginal ? report.category_vaginal : report.category_gut,
  };
};

const filterAndSortSpecies = (species: BacteriumType[]) => {
  return species
    .filter(specie => !!specie.classified_relative_abundance)
    .sort((a, b) => {
      return (
        (b.classified_relative_abundance ?? 0) -
        (a.classified_relative_abundance ?? 0)
      );
    });
};

const StrainRow = ({ brand }: { brand: string }) => {
  return (
    <div className='flex align-items-center gap-1'>
      <Icon name='check' size='s' className={styles.greyIcon} />
      <Typography variant='label' className={styles.greyText}>
        {brand}
      </Typography>
    </div>
  );
};

const StrainsDetected = ({
  strains,
}: {
  strains?: GutVaginalHealthStrainKits[];
}) => {
  if (!strains?.length)
    return (
      <div className={styles.strainsContainer}>
        <div className='flex align-items-center gap-3'>
          <Icon name='clipboardX' size='m' className={styles.redIcon} />
          <Typography variant='body-s'>{`We didn't find any specific strains from our database in your sample.`}</Typography>
        </div>
      </div>
    );

  const groupedStrains = strains.reduce(
    (
      grouped: { [key: string]: GutVaginalHealthStrainKits[] },
      strain: GutVaginalHealthStrainKits,
    ) => {
      const categoryKey = strain?.strain_name;
      if (categoryKey && grouped[categoryKey]) {
        grouped[categoryKey]!.push(strain);
      } else if (categoryKey) {
        grouped[categoryKey] = [strain];
      }
      return grouped;
    },
    {},
  );

  return (
    <div className={styles.strainsContainer}>
      {Object.entries(groupedStrains).map(([strainName, groupOfBrands]) => {
        return (
          <div key={`card_${strainName}`}>
            <div className='flex flex-column gap-2'>
              <Typography variant='body-s'>{strainName}</Typography>
              {!!groupOfBrands.filter(brand => !!brand.probiotic_brand)
                .length && (
                <div className='flex flex-column gap-2'>
                  {groupOfBrands.map(
                    brand =>
                      !!brand.probiotic_brand && (
                        <StrainRow
                          brand={brand.probiotic_brand}
                          key={strainName + '_' + brand.probiotic_brand}
                        />
                      ),
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

type SubMetricDataProps = {
  metric: InsightMetric;
  relatedActionsQty: number;
  refAP: React.RefObject<HTMLElement>;
};
export const SubMetricData = ({
  metric,
  relatedActionsQty,
  refAP,
}: SubMetricDataProps) => {
  const { kitId } = useParams<{ kitId: string }>();
  const { isFeatureEnabled: showToxins } = useFeature('display_toxins');
  const source = useMemo(() => {
    return getFirstParagraph(metric?.about);
  }, [metric?.about]);

  const isVaginal = useMemo(
    () => metric?.sampling_type === 'vaginal',
    [metric],
  );
  const associations = useMemo(
    () => metric?.gut_vaginal_health?.associations_list || [],
    [metric?.gut_vaginal_health?.associations_list],
  );

  const relatedSpecies: BacteriumType[] = useMemo(() => {
    const species: BacteriumType[] = [];
    const reports = metric?.gut_vaginal_health?.report_taxa_categories;
    if (reports && reports.length) {
      reports.forEach(report => {
        species.push(bacteriumFromReport(report, isVaginal));
      });
      return filterAndSortSpecies(species);
    }
    return [];
  }, [metric, isVaginal]);

  const showStrainSection =
    !!metric.include_strains && !!metric.sample_qc?.probiotics_completed;
  const showToxinsSection = !!metric?.show_toxins && showToxins;
  const hasToxins = !!metric.gut_vaginal_health_function_kits?.length;
  if (!metric || !kitId) return <></>;
  return (
    <div className={styles.subMetricData}>
      <SubMetricGraphs metric={metric} />
      {!!relatedActionsQty && (
        <Card className={styles.actionsReminder}>
          <div className='flex gap-2'>
            <div className={styles.iconWrapper}>
              <Icon name='flashFilled' size='m' />
            </div>
            <div className='flex flex-column gap-1'>
              <Typography variant='heading-s'>{`${relatedActionsQty} actions related to this metric`}</Typography>
              <DesignButton
                label='See actions'
                iconNameRight='chevronForward'
                color={ButtonColors.PURPLE}
                variant={ButtonVariants.TEXT}
                onClick={async () => {
                  if (refAP.current) {
                    window.scrollTo({
                      top: refAP.current.offsetTop - 10,
                      behavior: 'smooth',
                    });
                  }
                }}
              />
            </div>
          </div>
        </Card>
      )}

      <div>
        <Typography variant='heading-m'>About</Typography>
        {metric?.evidence && (
          <EvidenceRating evidenceRating={metric.evidence} />
        )}
        <div className={c(styles.detailAbout, metric?.evidence && 'mt-2')}>
          <Markdown>{source ?? ''}</Markdown>
          <Modal
            title='About this metric'
            label={<span className={styles.detailMore}>Learn more</span>}
          >
            <div className='flex flex-column'>
              <Typography variant='heading-l' className='mb-2'>
                {metric.name}
              </Typography>
              <Markdown>{metric?.about ?? ''}</Markdown>
            </div>
          </Modal>
        </div>
      </div>

      {!!metric?.faq && (
        <div className='pb-4'>
          <Typography variant='heading-l' className={styles.detailHeading}>
            Frequently asked questions
          </Typography>
          <FAQ faq={metric.faq} />
        </div>
      )}

      {!!associations?.length && (
        <div>
          <Typography variant='heading-m'>Associations</Typography>
          <AssociationsList associations={associations} />
        </div>
      )}
      {!!metric?.gut_vaginal_health?.id && (
        <div className={styles.feedbackWidget}>
          <FeedbackWidget
            kitId={kitId}
            pageType={FeedbackPageType.INSIGHT}
            pageId={metric.gut_vaginal_health.id}
          />
        </div>
      )}
      {!!relatedSpecies?.length && (
        <div>
          <Typography variant='heading-m'>Species in your sample</Typography>
          <Card className='mt-2'>
            <div className={styles.bacteriumCard}>
              <ExpandableList max={4}>
                {relatedSpecies.map((bacterium, i) => (
                  <Bacterium
                    key={bacterium.rtc_id || i}
                    bacterium={bacterium}
                  />
                ))}
              </ExpandableList>
            </div>
          </Card>
        </div>
      )}
      {showStrainSection && (
        <div>
          <div className='flex flex-column gap-1'>
            <Typography variant='heading-m'>Strains detected</Typography>
            <Typography variant='body-s' className={styles.greyText}>
              These specific strains within this species were identified. For
              more information, see our{' '}
              <LinkButton
                label='FAQ'
                href={
                  'https://www.tinyhealth.com/blog/tiny-health-strain-tracking-faq'
                }
                external
                variant={ButtonVariants.TEXT}
                size={ButtonSizes.M}
                color={ButtonColors.PURPLE}
                className={styles.link}
              />
              .
            </Typography>
          </div>
          <StrainsDetected strains={metric.gut_vaginal_health_strain_kits} />
        </div>
      )}
      {showToxinsSection && (
        <div className='flex flex-column gap-3'>
          <Typography variant='heading-m'>Toxin genes</Typography>

          <Typography variant='body-s' className={styles.greyText}>
            These are notable toxin genes detected within the microbes in your
            sample. This measures the ability of the microbes to produce these
            toxins. It does not measure toxins directly.{' '}
            <TextLink
              label='See our FAQs.'
              href={LINKS.toxinFAQ}
              size='body-s'
              external
            />{' '}
            If you are experiencing symptoms, we recommend talking to your
            practitioner or a microbiome specialist.
          </Typography>

          <div className={styles.geneTrackingCard}>
            {hasToxins ? (
              uniqBy(
                metric.gut_vaginal_health_function_kits,
                gvhfk => gvhfk.gut_vaginal_health_function?.gene_name,
              ).map(gvhfk => {
                return (
                  <Accordion
                    key={gvhfk.id}
                    header={gvhfk.gut_vaginal_health_function?.gene_name ?? ''}
                    initialState={false}
                    className={styles.geneTrackingAccordion}
                  >
                    <Markdown>
                      {gvhfk.gut_vaginal_health_function?.about ?? ''}
                    </Markdown>
                  </Accordion>
                );
              })
            ) : (
              <div className='flex gap-2 p-3'>
                <Icon name='clipboardX' className='red' />
                <Typography variant='body-s'>
                  We didn&apos;t find any notable genes in your sample.
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
