import { NavLink, useLocation } from 'react-router-dom';
import c from 'classnames';
import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';

import {
  Icon,
  IconName,
  Typography,
  TextColor,
  TypographyComponentVariant,
} from '@repo/ui';

import styles from './TextLink.module.scss';

type TextLinkProps = {
  label: string;
  size?: TypographyComponentVariant;
  href?: string;
  color?: TextColor;
  external?: boolean;
  iconNameLeft?: IconName;
  iconNameRight?: IconName;
  iconSize?: 's' | 'm';
  onClick?: () => void;
  className?: string;
  state?: {
    [key: string]: string | number | null | undefined;
  };
};

export const TextLink = ({
  label,
  size = 'label',
  href,
  external,
  color,
  iconNameLeft,
  iconNameRight,
  iconSize = 's',
  onClick,
  className,
  state: navigationState,
}: TextLinkProps) => {
  const { pathname } = useLocation();
  const { trackEvent } = useAnalytics();
  const content = (
    <div
      className={c(styles.link, color && styles[color], className)}
      onClick={() => {
        onClick && onClick();
        trackEvent('Clicked Button', {
          text: label,
          href: href ?? '',
          isExternal: external ?? false,
          variety: 'TextLink',
        });
      }}
    >
      {iconNameLeft && <Icon name={iconNameLeft} size={iconSize} />}
      <Typography variant={size} color={color}>
        {label}
      </Typography>
      {iconNameRight && <Icon name={iconNameRight} size={iconSize} />}
    </div>
  );
  if (href) {
    if (external) {
      return (
        <a
          href={href}
          target='_blank'
          className={c(styles.link, className)}
          rel='noreferrer'
          //flag to know that it is already tracked and avoid double tracking in the general link tracking from AnalyticsProvider
          data-tracking-source='TextLink'
        >
          {content}
        </a>
      );
    } else {
      return (
        <NavLink
          to={href}
          className={c(styles.link, className)}
          state={{ ...navigationState, from: pathname }}
        >
          {content}
        </NavLink>
      );
    }
  }
  // externally handled onClick, no href
  return content;
};
