import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import c from 'classnames';
import { v4 } from 'uuid';

import { useActionPlanItemNonSuspense } from 'services/actionPlan/actionPlanItem';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';

import {
  Accordion,
  ButtonColors,
  ButtonVariants,
  DesignButton,
  TextLink,
} from 'components';
import { Icon, IconName, Typography, TextColor } from '@repo/ui';
import { useUrlQuery } from 'hooks/useUrlQuery';

import { ActionPlanItem } from 'types/ActionPlan';

import styles from './ActionPlanGroup.module.scss';

export type OutcomeWithItems = {
  name: string;
  metricId?: number | null;
  color: string;
  order: number;
  actions: (ActionPlanItem & { hide?: boolean })[];
  section?: string;
  hide?: boolean;
};
const getIconForAction = (action: ActionPlanItem): IconName => {
  switch (action.category) {
    case 'SupplementSuggestions':
      return 'pill';
    case 'DietarySuggestions':
      return 'carrot';
    case 'LifeStyleSuggestions':
      return 'heartHealth';
    default:
      return 'heartHealth';
  }
};
export const ActionPlanItemCard = ({
  action,
  kitId,
}: {
  action: ActionPlanItem;
  kitId: string;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryParams = useUrlQuery();
  const paramString = useMemo(() => {
    let paramString = '';
    if (queryParams) {
      paramString = '?';
      queryParams.forEach((value, key) => {
        paramString += `${key}=${value}&`;
      });
    }
    return paramString;
  }, [queryParams]);

  // prefetch action plan items
  useActionPlanItemNonSuspense({ kitId, actionId: action.id });
  return (
    <div
      className={styles.actionCard}
      key={action.id}
      onClick={() =>
        navigate(`/action-plan/${kitId}/action/${action.id}`, {
          state: { from: pathname + paramString },
        })
      }
    >
      <Icon name={getIconForAction(action)} size='m' className='mr-2' />
      <Typography variant='heading-s'>{action.display_title}</Typography>
      <TextLink
        label='More'
        iconNameRight='chevronForward'
        className={'my-auto ml-auto gap-0'}
      />
    </div>
  );
};
const ActionPlanGroup = ({
  kitId,
  title,
  icon,
  description,
  placeholder,
  color = 'grey',
  actionsGroupedByOutcome,
  defaultOpen = false,
  hideToggleIcon = false,
  clearFilters,
}: {
  kitId?: string;
  title: string;
  icon: IconName;
  description?: string;
  placeholder?: string;
  color?: string;
  actionsGroupedByOutcome?: {
    [outcome: string]: OutcomeWithItems;
  };
  defaultOpen?: boolean;
  hideToggleIcon?: boolean;
  clearFilters?: () => void;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const [accordionOpen, setAccordionOpen] = useState(defaultOpen);
  const totalHiddenActions = useMemo(
    () =>
      actionsGroupedByOutcome
        ? Object.values(actionsGroupedByOutcome).reduce(
            (acc, outcome) =>
              acc + outcome.actions.filter(action => action.hide).length,
            0,
          )
        : 0,
    [actionsGroupedByOutcome],
  );
  const kitIdToUse = kitId ?? currentKitId;
  useEffect(() => {
    // when switching between kits, reset the accordion state to the correct default
    defaultOpen && setAccordionOpen(true);
    !defaultOpen && setAccordionOpen(false);
  }, [defaultOpen, kitIdToUse]);
  const handleOutcomeClick = (metricId: number | null) => {
    if (metricId && kitIdToUse) {
      navigate(`/results/${kitIdToUse}/insights/all-insights/${metricId}`, {
        state: { from: pathname },
      });
    }
  };
  if (!kitIdToUse) {
    return null;
  }

  return (
    <motion.div className={styles.card}>
      <Accordion
        header={
          <div className={styles.header}>
            <div className={styles.iconBlock}>
              <div className={c(styles.iconContainer, styles[color])}>
                <Icon name={icon} size='m' className='m-auto' />
              </div>
            </div>

            <Typography variant='heading-l' className={styles.headerTitle}>
              {title}
            </Typography>
            {description && accordionOpen && (
              <motion.div
                initial='collapsed'
                animate='open'
                exit='collapsed'
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
                className={styles.headerDescription}
              >
                <Typography variant='body-s'>{description}</Typography>
              </motion.div>
            )}
          </div>
        }
        initialState={accordionOpen}
        isOpen={accordionOpen}
        hideToggleIcon={hideToggleIcon}
        onToggle={() => setAccordionOpen(!accordionOpen)}
      >
        <div className={styles.content}>
          {actionsGroupedByOutcome && (
            <>
              {Object.entries(actionsGroupedByOutcome)
                .sort(
                  (
                    [outcomeA, { order: orderA }],
                    [outcomeB, { order: orderB }],
                  ) => orderA - orderB,
                )
                .map(
                  ([
                    outcome,
                    { name, color, actions, hide, metricId, section },
                  ]) => {
                    const isMetricLink =
                      metricId && section === 'Gut Vaginal Health'
                        ? true
                        : false;
                    return (
                      <div key={v4()}>
                        {!hide && (
                          <div
                            className={styles.outcome}
                            key={`outcome-${metricId}-${v4()}`}
                          >
                            <div
                              className={c(
                                styles.outcomePill,
                                styles[color],
                                isMetricLink && styles.clickable,
                              )}
                              onClick={() =>
                                metricId &&
                                isMetricLink &&
                                handleOutcomeClick(metricId)
                              }
                            >
                              <Typography variant='label'>{name}</Typography>
                              {isMetricLink && (
                                <Icon name='trendUp02' size='s' />
                              )}
                            </div>

                            {actions
                              .sort(
                                (a, b) =>
                                  (a.display_order ?? 0) -
                                  (b.display_order ?? 0),
                              )
                              .filter(action => !action.hide)
                              .map(action => (
                                <ActionPlanItemCard
                                  key={`action-${action.id}-${v4()}`}
                                  action={action}
                                  kitId={kitIdToUse}
                                />
                              ))}
                          </div>
                        )}
                      </div>
                    );
                  },
                )}

              {totalHiddenActions > 0 && (
                <div className={styles.clearFilters}>
                  <Typography
                    variant='label'
                    color={TextColor.DARK}
                    className='my-auto'
                  >
                    {`${totalHiddenActions} actions hidden by filters`}
                  </Typography>
                  <DesignButton
                    label='Clear Filters'
                    iconNameLeft='closeSm'
                    onClick={async () => await clearFilters?.()}
                    variant={ButtonVariants.TEXT}
                    color={ButtonColors.DARK}
                    className={styles.clearFiltersButton}
                  />
                </div>
              )}
            </>
          )}
          {!actionsGroupedByOutcome && (
            <div className={styles.emptyState}>
              <Typography variant='body-s'>
                {placeholder ? placeholder : 'No actions needed'}
              </Typography>
            </div>
          )}
        </div>
      </Accordion>
    </motion.div>
  );
};
export default ActionPlanGroup;
