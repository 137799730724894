import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets';
import { getPractitionerCode } from 'store/user';
import { PractitionerKit } from 'types/PractitionerUser';

import testPackage from 'assets/images/practitioners/test-gut.jpg';
import testPackageVaginal from 'assets/images/practitioners/test-vag.jpg';
import testPackagePRO from 'assets/images/practitioners/test-PRO.jpg';

const PRACTITIONER_20_OFF = 20;
export const DISPLAY_NAMES: { [key: string]: string } = {
  GUT_HEALTH_TEST_PRACTITIONER: 'Gut Health Test',
  VAGINAL_HEALTH_TEST_PRACTITIONER: 'Vaginal Health Test',
  TINY_PRO_GUT_HEALTH_TEST: 'PRO Gut Health Test',
};
export const STORE_LINKS: { [key: string]: string } = {
  GUT_HEALTH_TEST_PRACTITIONER:
    // 'https://www.poweredbytiny.com/products/gut-health-test',
    'https://www.poweredbytiny.com/products/gut-health-test-order',
  VAGINAL_HEALTH_TEST_PRACTITIONER:
    // 'https://www.poweredbytiny.com/products/vaginal-health-test',
    'https://www.poweredbytiny.com/products/vaginal-health-test-order',
  TINY_PRO_GUT_HEALTH_TEST:
    // 'https://www.poweredbytiny.com/products/pro-gut-health-test',
    'https://www.poweredbytiny.com/products/pro-gut-health-test-order',
};
export const KIT_IMAGES: { [key: string]: string } = {
  GUT_HEALTH_TEST_PRACTITIONER: testPackage,
  VAGINAL_HEALTH_TEST_PRACTITIONER: testPackageVaginal,
  TINY_PRO_GUT_HEALTH_TEST: testPackagePRO,
};

export async function getPractitionerKitsForPatient() {
  const response = await API.get(
    apiName,
    `${apiBasePath}/practitioners/patient-invoices/kits`,
    {},
  );
  return response?.result;
}

const usePractitionerKitsForPatient = () => {
  const practitionerCode = useSelector(getPractitionerCode);
  const practitionerKitsForPatientQuery = useQuery<PractitionerKit[]>({
    queryKey: [apiName, `practitionerKitsForPatient`],
    queryFn: () => getPractitionerKitsForPatient(),
  });

  return {
    ...practitionerKitsForPatientQuery,
    data: useMemo(
      () =>
        practitionerKitsForPatientQuery.data?.map(kit => {
          // If practitioner code is present, show the price with discount (api price is without discount)
          const kitPrice = practitionerCode
            ? kit.price - PRACTITIONER_20_OFF
            : kit.price;
          const display_title = `${
            DISPLAY_NAMES[kit.lookup_code]
              ? DISPLAY_NAMES[kit.lookup_code]
              : kit.name
          } - $${kitPrice}`;
          return {
            ...kit,
            display_title,
            display_name: DISPLAY_NAMES[kit.lookup_code],
            store_link: STORE_LINKS[kit.lookup_code],
            price_with_discounts: kitPrice,
          };
        }),
      [practitionerKitsForPatientQuery.data],
    ),
  };
};

export default usePractitionerKitsForPatient;
