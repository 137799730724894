import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import c from 'classnames';

import {
  colorForTinyAccount,
  parseDate,
  stringToUrl,
  userValueFormat,
} from 'helpers';
import useActionPlanItem from 'services/actionPlan/actionPlanItem';
import useAllKitsInsight from 'services/actionPlan/useAllKitsInsight';
import useActionPlanData from 'services/actionPlan/actionPlanData';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getTinyAccountsOrderedByLatestKitResults } from 'store/account';
import { getKits } from 'store/shared';
import { ActionPlanDesiredOutcome, ActionPlanItem } from 'types/ActionPlan';
import { InsightMetric } from 'types/Insights';

import { PageContainer, ContainerSizes, Markdown } from 'components';
import { Icon } from '@repo/ui';
import { BackButton } from 'components/BackButton/BackButton';

import styles from './ActionRelatedInsights.module.scss';

const filterOutcomesBySampleHealthAndRecommendation = (
  data: ActionPlanItem,
  sampleHealthIds: number[] = [],
  actionPlanRecommendationId: string,
) => {
  const cardData = {
    ...data,
    display_title: data.display_title,
    detail: data.detail,
    desired_outcomes: sampleHealthIds.length
      ? data.desired_outcomes?.filter(
          outcome =>
            !!outcome.criteria &&
            sampleHealthIds.includes(parseInt(outcome.criteria)) &&
            outcome.action_plan_recommendations_id ===
              actionPlanRecommendationId,
        )
      : data.desired_outcomes,
    id: data.id,
  };
  return cardData;
};

const filterMetrics = (
  metrics: InsightMetric[],
  ids: number[],
): InsightMetric[] => {
  return metrics?.filter(metric => ids.includes(metric.sample_health_id)) ?? [];
};
const getListOfHealthIdsFromAction = (
  actionPlanItems: ActionPlanItem[],
): number[] => {
  let ids: number[] = [];
  if (!actionPlanItems?.length) return ids;
  actionPlanItems.forEach(item => {
    const tempIds = item.desired_outcomes?.map(
      outcome => outcome?.gut_vaginal_health?.id,
    );
    if (tempIds?.length) {
      ids = [...ids, ...(tempIds.filter(id => id !== undefined) as number[])];
    }
  });
  return ids;
};

export const ActionRelatedInsights = () => {
  const { actionId } = useParams<{ actionId: string }>();
  const { currentKitId, currentTinyAccountId, currentTinyAccount } =
    useCurrentKitAndTinyAccount();
  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );

  const { data: action } = useActionPlanItem({ kitId: currentKitId, actionId });
  const { data: metrics } = useAllKitsInsight(currentTinyAccountId);

  const desiredOutcomesGVH = useMemo(() => {
    const outcomesGvh = action?.desired_outcomes?.map(
      (outcome: ActionPlanDesiredOutcome) => outcome.criteria,
    );
    return outcomesGvh
      ?.map((item: string) => Number(item))
      .filter((item: number) => !Number.isNaN(item));
  }, [action]);
  const relatedMetrics = useMemo(() => {
    return !metrics?.length
      ? []
      : metrics.filter((metric: InsightMetric) =>
          desiredOutcomesGVH.includes(metric.sample_health_id),
        );
  }, [desiredOutcomesGVH, metrics]);

  const uniqueKits: Set<string> = new Set(
    relatedMetrics.map((metric: InsightMetric) => metric.kit_id),
  );
  const uniqueKitsList: string[] = Array.from(uniqueKits);
  if (!actionId || !currentKitId || !currentTinyAccountId) return null;
  return (
    <PageContainer size={ContainerSizes.MD} className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <NavLink to={`/action-plan/${currentKitId}/action/${actionId}`}>
          <BackButton variant='link' />
        </NavLink>
        <div className={styles.headerTitle}>Related insights</div>
        <div className={styles.placeholder}>&nbsp;</div>
      </div>
      <div className={styles.content}>
        {!!currentTinyAccount && (
          <div className={styles.accountContainer}>
            <div
              className={c(styles.avatarBubble)}
              style={{
                backgroundColor: colorForTinyAccount(
                  orderedTinyAccounts,
                  currentTinyAccount.id,
                ),
              }}
            >
              {currentTinyAccount.first_name.substr(0, 1)}
            </div>
            <span className={styles.name}>{currentTinyAccount.first_name}</span>
          </div>
        )}
        <div className={styles.kitRelatedCards}>
          {uniqueKitsList.map(kitId => (
            <RelatedMetrics
              kitId={kitId}
              actionId={actionId}
              metrics={relatedMetrics.filter(
                (metric: InsightMetric) => metric.kit_id === kitId,
              )}
              desiredOutcomesGVH={desiredOutcomesGVH}
              key={kitId}
            />
          ))}
        </div>
      </div>
    </PageContainer>
  );
};

const RelatedMetrics = ({
  kitId,
  actionId,
  metrics,
  desiredOutcomesGVH,
}: {
  kitId: string;
  actionId: string;
  metrics: InsightMetric[];
  desiredOutcomesGVH: number[];
}) => {
  const { pathname } = useLocation();
  const kits = useSelector(getKits);
  const thisKit = useMemo(
    () => kits.find(kit => kit.id === kitId),
    [kits, kitId],
  );
  const { data: allKitActions } = useActionPlanData({ kitId: kitId });
  const actionPlanItemsForCurrent = useMemo(
    () =>
      allKitActions?.map((item: ActionPlanItem) =>
        filterOutcomesBySampleHealthAndRecommendation(
          item as ActionPlanItem,
          desiredOutcomesGVH,
          actionId,
        ),
      ),
    [allKitActions, desiredOutcomesGVH, actionId],
  );
  const displayMetrics = useMemo(
    () =>
      actionPlanItemsForCurrent &&
      filterMetrics(
        metrics,
        getListOfHealthIdsFromAction(actionPlanItemsForCurrent),
      ),
    [metrics, actionPlanItemsForCurrent],
  );

  if (!metrics?.length || !displayMetrics?.length) return null;
  return (
    <div className={styles.relatedMetricContainer}>
      <div className={styles.kitHeader}>
        {`${
          thisKit?.sampling_type === 'vaginal' ? 'VAG' : 'GUT'
        } TEST @ ${format(parseDate(thisKit?.sample_date), 'MM/dd/yy')}`}
      </div>
      <div className={styles.metricsWrapper}>
        <div className={styles.metricsHeader}>Related metrics</div>
        {displayMetrics.map(metric => (
          <NavLink
            to={{
              pathname: `/results/${kitId}/insights/${stringToUrl(
                metric?.gut_vaginal_health?.key_insights_category
                  ?.key_insights_sections?.display_title ?? '',
              )}/${metric.sample_health_id}`,
            }}
            state={{ from: pathname }}
            className={styles.metricRow}
            key={metric.category_key + metric.name}
          >
            <div className={styles.metricContent}>
              <div className={styles.main}>
                {`${metric.name}: `}
                <span
                  className={c(
                    styles.insightValue,
                    styles[metric.evaluation_color],
                  )}
                >
                  {userValueFormat(
                    metric.user_value,
                    metric.data_type,
                    metric.user_string,
                  )}
                </span>
              </div>
              <Markdown className={styles.secondary}>
                {metric.detected_text}
              </Markdown>
            </div>
            <div className={styles.action}>
              <Icon name='chevronForward' size='m' />
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
