import { useEffect } from 'react';
import c from 'classnames';

import { LINKS } from 'assets';
import useImpersonate from 'services/auth/useImpersonate';
import usePractitionerKitsForPatient, {
  KIT_IMAGES,
} from 'services/practitioner/usePractitionerKitsForPatient';
import { PractitionerKit } from 'types/PractitionerUser';

import {
  ButtonColors,
  ButtonSizes,
  Card,
  ContainerSizes,
  LinkButton,
  PageContainer,
  TextLink,
  Typography,
} from 'components';

import styles from './OrderKitsStore.module.scss';

const PractitionerKitCard = ({ kit }: { kit: PractitionerKit }) => {
  return (
    <Card variant='wrapper' className='h-100'>
      <div className={styles.cardContent}>
        <div className={styles.imageWrapper}>
          <img
            src={KIT_IMAGES[kit.lookup_code]}
            alt={`Test package`}
            loading='lazy'
          />
        </div>
        <div className='flex align-items-center justify-content-between gap-1'>
          <Typography variant='heading-l'>
            {kit.display_name || kit.name}
          </Typography>
          <Typography variant='heading-s'>{`$${kit.price_with_discounts ? kit.price_with_discounts : kit.price}`}</Typography>
        </div>
        <Typography variant='body-s' className={c(styles.coldGrey, 'mt-2')}>
          Insights on the commensal and pathogenic species in the microbiome
          with accurate relative abundances.
        </Typography>
        {!!kit?.store_link && (
          <div className={styles.buttonsContainer}>
            <LinkButton
              label='Learn more'
              href={kit.store_link}
              color={ButtonColors.LIGHT}
              size={ButtonSizes.M}
              width='full'
              external
            />
            <LinkButton
              label='Shop Now'
              href={kit.store_link}
              color={ButtonColors.DARK_PURPLE}
              size={ButtonSizes.M}
              width='full'
              external
            />
          </div>
        )}
      </div>
    </Card>
  );
};

const OrderKitsStore = () => {
  const { impersonateId, stopImpersonating } = useImpersonate();
  const { data: practitionerKits } = usePractitionerKitsForPatient();

  useEffect(() => {
    if (impersonateId) {
      stopImpersonating();
    }
  }, []);

  return (
    <div className={styles.mainContent}>
      <TextLink label='Go Back' href='/order-kits' iconNameLeft='chevronBack' />
      <Typography variant='heading-xl'>Practitioner Pay</Typography>
      <Typography variant='body-s' className={styles.coldGrey}>
        If you would like to pay for kits upfront, you can purchase them
        directly here. You can have them shipped to you or the patient.{' '}
        <TextLink
          label='See FAQs'
          href={LINKS.orderTestPractitionerPayHelp}
          external
          size='body-s'
        />
      </Typography>
      <PageContainer size={ContainerSizes.XL}>
        <div className={c(styles.kitsGrid)}>
          {practitionerKits?.map(kit => (
            <PractitionerKitCard kit={kit} key={kit.product_id} />
          ))}
        </div>
      </PageContainer>
    </div>
  );
};

export default OrderKitsStore;
