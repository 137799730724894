import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useAccountsAndKits from 'services/account/useAccountsAndKits';
import useFeature from 'services/features/useFeature';
import { useSystemInfo } from 'services/system-info/useSystemInfo';
import { getTinyAccountsOrderedByLatestKitResults } from 'store/account';
import { getHomePageTinyAccountId, setHomePageTinyAccountId } from 'store/home';
import {
  getImpersonateId,
  getIsActivePractitioner,
  getIsAdminUser,
  getIsAuthenticated,
  getIsAutoLoggedOut,
} from 'store/user';
import { Spinfinity } from 'components';
import AuthLayout from 'components/Layout/AuthLayout';
import ResponsiveLayout from 'components/Layout/ResponsiveLayout';
import { useSubdomain } from 'hooks/useSubdomain';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { SplashScreen } from 'views/Auth';
import { GetStarted } from './GetStarted/GetStarted';
import { HomeContent } from './HomeContent/HomeContent';

const Home = () => {
  const dispatch = useDispatch();
  const { state: navigationState } = useLocation();
  const tinyAccountIdParam = useUrlQuery().get('account');
  useAccountsAndKits();
  useSystemInfo();
  const { isFeatureEnabled, isFetched: isFeatureFetched } =
    useFeature('home_screen');
  const { isPractitionerPortal, isLoaded: isSubdomainLoaded } = useSubdomain();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const impersonateId = useSelector(getImpersonateId);
  const isActivePractitioner = useSelector(getIsActivePractitioner);
  const isAdmin = useSelector(getIsAdminUser);
  const isAdminOrPractitioner = isActivePractitioner || isAdmin;
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const tinyAccounts = useSelector(getTinyAccountsOrderedByLatestKitResults);
  const isAutoLoggedOut = useSelector(getIsAutoLoggedOut);
  // set the home page tiny account
  useEffect(() => {
    if (!isFeatureEnabled || !isFeatureFetched || !tinyAccounts?.length) return;
    // if tinyAccountIdParam or navigation state is present, set that as the home page tiny account
    let newTinyAccountId: string | null = null;
    if (tinyAccountIdParam || navigationState?.tinyAccountId) {
      newTinyAccountId =
        navigationState?.tinyAccountId ?? (tinyAccountIdParam as string);
    } else if (!tinyAccountId) {
      // if no tiny account is set, set the tiny account with the most recent kit as the home page tiny account
      newTinyAccountId = tinyAccounts[0].id;
    }

    if (
      newTinyAccountId !== tinyAccountId &&
      tinyAccounts?.some(account => account.id === newTinyAccountId)
    ) {
      dispatch(setHomePageTinyAccountId(newTinyAccountId));
    }
  }, [
    dispatch,
    isAuthenticated,
    isFeatureEnabled,
    isFeatureFetched,
    navigationState,
    tinyAccountId,
    tinyAccountIdParam,
    tinyAccounts,
  ]);
  if (isAutoLoggedOut) {
    return (
      <Navigate
        to='/login'
        replace
        state={{
          ...navigationState,
          tinyAccountId: tinyAccountIdParam
            ? tinyAccountIdParam
            : tinyAccountId,
        }}
      />
    );
  }
  if (!isSubdomainLoaded) {
    return (
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='' element={<Spinfinity />} />
        </Route>
      </Routes>
    );
  }
  if (!isAuthenticated) {
    return (
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='' element={<SplashScreen />} />
        </Route>
      </Routes>
    );
  }
  if (isPractitionerPortal && !impersonateId && isAdminOrPractitioner) {
    return <Navigate to='/portal' replace />;
  }

  let HomeScreenAction = null;
  if (!isFeatureFetched) {
    HomeScreenAction = <Spinfinity />;
  } else if (!isFeatureEnabled) {
    HomeScreenAction = (
      <Navigate to='/results' replace state={navigationState} />
    );
  } else if (!tinyAccounts?.length) {
    HomeScreenAction = <GetStarted />;
  } else if (tinyAccountId) {
    HomeScreenAction = <HomeContent />;
  }

  return (
    <Routes>
      <Route element={<ResponsiveLayout />}>
        <Route path='' element={HomeScreenAction} />
      </Route>
    </Routes>
  );
};

export default Home;
