import { forwardRef, Ref } from 'react';
import c from 'classnames';
import { Typography } from '@repo/ui';
import MicrobiomeScoreChart from 'components/MicrobiomeScoreChart/MicrobiomeScoreChart';
import { getOrdinalSuffix } from 'helpers/getOrdinalSuffix';
import Kit from 'types/Kit';
import { getPopScoreInfo } from 'views/Results/Summary/SummaryCards/MicrobiomeScoreSummaryCard/MicrobiomeScoreSummaryCard';
import LogoSvg from 'assets/images/logos/logo-full.svg?react';
import MyGutHealthResults from 'assets/images/my-gut-health-results.svg?react';
import styles from './MicrobiomeScoreSummaryCard.module.scss';

const MicrobiomeScoreShareImage = forwardRef(function MicrobiomeScoreShareImage(
  { kit, popScore }: { kit: Kit; popScore: number },
  ref,
) {
  const { percentile, color, ageText } = getPopScoreInfo(kit, popScore);
  return (
    <div
      className={styles.cardContainer}
      style={{ width: 362, height: 362 }}
      ref={ref as Ref<HTMLDivElement> | null}
    >
      {' '}
      <div className={styles.title}>
        <MyGutHealthResults />
      </div>
      <div className={c(styles.titleContainer, styles.isImage)}>
        <Typography variant='heading-s'>Your results score</Typography>
        <div className={styles.percentile}>
          <span className={c(styles.percentileBig, styles[color ?? 'grey'])}>
            {Math.round(popScore)}
          </span>
        </div>
        <div className='flex flex-column gap-1'>
          <Typography variant='label' medium>
            {`${Math.floor(percentile)}${getOrdinalSuffix(Math.floor(percentile))} percentile `}
          </Typography>
          <Typography variant='label'> of {ageText}</Typography>
        </div>
      </div>
      <div className={styles.chartContainer}>
        <MicrobiomeScoreChart
          kit={kit}
          popScore={popScore}
          // disableAnimations
          isImage
        />
      </div>
      <div className={styles.logoContainer}>
        <LogoSvg />
      </div>
    </div>
  );
});

export default MicrobiomeScoreShareImage;
