import c from 'classnames';

import {
  Association,
  AssociationEvaluation,
  AssociationDirection,
} from 'types/Association';

import { Icon } from '@repo/ui';

import styles from './AssociationsList.module.scss';

const hasPositiveImpact = (association: Association) => {
  if (association.evaluation === AssociationEvaluation.GOOD) {
    return association.direction === AssociationDirection.POSITIVE;
  }
  if (association.evaluation === AssociationEvaluation.BAD) {
    return association.direction === AssociationDirection.NEGATIVE;
  }
};

export const AssociationsList = ({
  associations,
}: {
  associations: Association[];
}) => {
  if (!associations?.length) return <></>;
  return (
    <div className={styles.associations}>
      {associations.map(association => (
        <div
          key={association.id}
          className={c(
            styles.associationPill,
            styles.wordBreak,
            hasPositiveImpact(association)
              ? styles.pillPositive
              : styles.pillNegative,
          )}
        >
          {!!association?.direction && (
            <div className={styles.iconWrapper}>
              <Icon
                name={
                  association?.direction === AssociationDirection.POSITIVE
                    ? 'upSmall'
                    : 'downSmall'
                }
                size='s'
              />
            </div>
          )}
          <span>{association.display_name}</span>
        </div>
      ))}
    </div>
  );
};
