import c from 'classnames';
import { ButtonColors, LinkButton, TextLink, Typography } from 'components';

import styles from './GenericSummaryCardWithImage.module.scss';

export interface GenericSummaryCardWithImageProps {
  title: string;
  section?: string;
  image?: string;
  expandVertically?: boolean;
  link?: string;
  cta?: string;
  external?: boolean;
  overlay?: React.ReactNode;
  children?: React.ReactNode;
}

export const GenericSummaryCardWithImage: React.FC<
  GenericSummaryCardWithImageProps
> = ({
  title,
  section,
  link,
  cta,
  image,
  expandVertically = false,
  external = false,
  overlay,
  children,
}) => {
  return (
    <div
      className={c(
        styles.cardContainer,
        expandVertically && styles.verticalExpand,
      )}
    >
      <div className={c(styles.cardContent, !image && styles.noImage)}>
        <div className={styles.cardTextContainer}>
          {!!section && (
            <Typography variant={'label'} medium>
              {section}
            </Typography>
          )}
          {!!title && (
            <Typography variant={'heading-l'} medium>
              {title}
            </Typography>
          )}
        </div>
        {children}
        {!!link && !!cta && (
          <TextLink
            label={cta}
            href={link}
            external={external}
            iconNameRight='chevronForward'
          />
        )}
      </div>
      {image && (
        <div className={styles.imageContainer}>
          <img src={image} alt='Illustration' />
          {!!overlay && <div className={styles.overlay}>{overlay}</div>}
        </div>
      )}
    </div>
  );
};
