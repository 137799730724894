import ReactSelect from 'react-select';
import c from 'classnames';

import styles from './Select.module.scss';
import { Typography } from 'components';

export const Select = ({
  value,
  options,
  onChange,
  name,
  error = false,
  enableSearch = false,
  placeholder = 'Select one...',
  label,
  labelInside,
  ...props
}: {
  value?: string | number | null;
  options: { value: string | number; label: string }[];
  onChange: (value: string | number) => void;
  name: string;

  error?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
  label?: string;
  labelInside?: boolean;
}) => {
  return (
    <div
      className={c(styles['select-wrapper'], {
        [styles['with-label']]: label,
        [styles['label-inside']]: labelInside,
      })}
    >
      {label && (
        <label className={styles['select-label']}>
          <Typography variant='label'>{label}</Typography>
        </label>
      )}
      <ReactSelect
        className={styles.select}
        classNamePrefix={c(styles.select, error && styles.error)}
        name={name}
        aria-labelledby={name}
        placeholder={placeholder}
        options={options}
        value={options.find(option => option.value === value) ?? null}
        onChange={option => option && onChange(option.value)}
        isSearchable={enableSearch}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
          }),
        }}
        {...props}
      />
    </div>
  );
};

export default Select;
