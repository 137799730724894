import c from 'classnames';

import { Condition, ConditionEvaluationMeaning } from 'types/Conditions';
import Kit from 'types/Kit';

import { Card, Markdown, TextLink } from 'components';
import { Icon, IconName } from '@repo/ui';
import styles from './Conditions.module.scss';

type ConditionCardProps = {
  condition: Condition;
  title?: string;
  kit?: Kit | null;
  withFooter?: boolean;
  withDetails?: boolean;
  withEvaluationText?: boolean;
};

const getIconNameFromEvaluationMeaning = (
  evaluation_meaning: ConditionEvaluationMeaning,
): IconName => {
  let icon = '';
  switch (evaluation_meaning) {
    case ConditionEvaluationMeaning.FLAG:
      icon = 'alertTriangleFilled';
      break;
    case ConditionEvaluationMeaning.BORDERLINE:
      icon = 'alertCircleFilled';
      break;
    case ConditionEvaluationMeaning.CLEAR:
      icon = 'checkFilled';
      break;
  }
  return icon as IconName;
};

export const ConditionCard = ({
  condition,
  kit,
  withFooter,
  withEvaluationText,
  withDetails,
  title,
}: ConditionCardProps) => {
  return (
    <Card
      title={
        title ? (
          <span className={styles.title}>{condition.display_title}</span>
        ) : null
      }
    >
      <div
        className={c(
          styles.conditionEvaluation,
          styles['evaluation-' + condition.evaluation_meaning],
        )}
      >
        {condition.evaluation_meaning !==
          ConditionEvaluationMeaning.NEUTRAL && (
          <Icon
            name={getIconNameFromEvaluationMeaning(
              condition.evaluation_meaning,
            )}
            size='m'
          />
        )}
        <span>{condition.evaluation}</span>
      </div>
      {withEvaluationText && condition?.evaluation_text && (
        <div className={styles.secondaryText}>
          <Markdown>{condition.evaluation_text}</Markdown>
        </div>
      )}
      {!!kit && !withFooter && (
        <div className={styles.data}>
          <div className={styles.flexRow}>
            <Icon name='atSignFilled' size='xs' />
            <span>{`Tested at ${kit.sample_label}`}</span>
          </div>
          <div className={styles.flexRow}>
            <Icon name='markerPin02Filled' size='xs' />
            {`Checkpoints: ${condition.checkpoints}`}
          </div>
        </div>
      )}
      {!!kit && withDetails && (
        <div className={styles.details}>
          <TextLink
            label='View details'
            iconNameRight='chevronForward'
            href={`/results/${kit.id}/conditions/${encodeURIComponent(
              condition.condition,
            )}`}
          />
        </div>
      )}
      {withFooter && condition?.checkpoints ? (
        <span className={styles.footer}>
          <Icon name='markerPin02Filled' size='xs' />
          {`Checkpoints: ${condition.checkpoints}`}
        </span>
      ) : null}
    </Card>
  );
};
