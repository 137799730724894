import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import useProfileQuestions from 'services/general/profileQuestions';
import useSampleInfoQuestions from 'services/surveys/useSampleInfoQuestions';
import useUpdateSampleInfoAnswer from 'services/surveys/useUpdateSampleInfoAnswer';
import useUpdateProfileAnswer from 'services/surveys/useUpdateProfileAnswer';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import useFeature from 'services/features/useFeature';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { setHomePageTinyAccountId } from 'store/home';
import { getKitById } from 'store/kits';
import {
  ProfileQuestionAnswer,
  ProfileQuestionsGroup,
} from 'types/ProfileQuestions';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  ContainerSizes,
  DesignButton,
  Header,
  LinkButton,
  Modal,
  ModalBody,
  PageContainer,
  Typography,
} from 'components';
import { ProfileQuestionItem } from './ProfileQuestion';
import { ProfileHeader } from './ProfileHeader/ProfileHeader';
import { ProfileEditGroup } from './ProfileConfirmation/ProfileEditGroup';
import ProfileConfirmation from './ProfileConfirmation/ProfileConfirmation';
import profileStartImage from 'assets/images/profiles/profile_start.svg';
import profileResumeImage from 'assets/images/profiles/profile_resume.svg';
import sampleInfoStartImage from 'assets/images/profiles/sample_info_start.svg';

import styles from './Profile.module.scss';

const RedirectToResultsPage = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  return <Navigate to={`/results/${currentKitId}`} replace />;
};

export const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path='' element={<Profile />} />
      <Route path='/:kitId/confirm' element={<ProfileConfirmation />} />
      <Route path='/:kitId/view' element={<ProfileConfirmation viewOnly />} />
      <Route
        path='/:kitId/confirm/:questionGroupId'
        element={<ProfileEditGroup />}
      />
      {/* This can only happen when losing the "state.from" */}
      <Route path='/:kitId' element={<RedirectToResultsPage />} />
    </Routes>
  );
};

const areTheAnswersEqual = (
  previousAnswer: string | string[] | number,
  currentAnswer: string | string[] | number,
): boolean => {
  // Convert both answers to arrays for easier comparison
  const prevArray = Array.isArray(previousAnswer)
    ? previousAnswer
    : [previousAnswer + ''];
  const currArray = Array.isArray(currentAnswer)
    ? currentAnswer
    : [currentAnswer + ''];
  // Sort arrays to ensure order doesn't matter in comparison
  const sortedPrevArray = prevArray.sort();
  const sortedCurrArray = currArray.sort();
  // Compare the lengths first
  if (sortedPrevArray.length !== sortedCurrArray.length) {
    return false;
  }
  // Compare each element
  for (let i = 0; i < sortedPrevArray.length; i++) {
    if (sortedPrevArray[i] !== sortedCurrArray[i]) {
      return false;
    }
  }
  return true;
};

export type ProfileFormData = {
  [key: string]: number | string | string[];
};
const Profile = () => {
  const tinyAccountId = useUrlQuery().get('account');
  const kitId = useUrlQuery().get('kit');
  const { isFeatureEnabled: showProfiles } = useFeature('profiles');
  const kit = useSelector(getKitById(kitId ?? ''));
  if (!showProfiles) return <></>;
  if (!tinyAccountId && !kitId) return <></>;
  if (tinyAccountId) {
    return <ProfileFlow tinyAccountId={tinyAccountId} />;
  }
  if (kitId) {
    return kit?.tinyaccount_id ? (
      <SampleInfoFlow tinyAccountId={kit.tinyaccount_id} kitId={kitId} />
    ) : (
      <></>
    );
  }
  return <></>;
};

const ProfileFlow = ({ tinyAccountId }: { tinyAccountId: string }) => {
  const { isFeatureEnabled: isHomeScreenEnabled } = useFeature('home_screen');
  const [initialLoad, setInitialLoad] = useState(true);
  const [showProfileStartModal, setShowProfileStartModal] = useState(false);
  const [showProfileResumeModal, setShowProfileResumeModal] = useState(false);
  const navigate = useNavigate();
  const kitIdForReview = useUrlQuery().get('review');
  const { data: profileQuestions } = useProfileQuestions(
    tinyAccountId,
    kitIdForReview ?? undefined,
  );
  const profileQuestionForFlow = useMemo(() => {
    if (kitIdForReview) {
      //if this is a review, only show the questions that have not been answered
      const groupsWithNewQuestions = profileQuestions?.filter(questionGroup =>
        questionGroup.questions.some(
          question =>
            question.answer_text === '' || question.answer_text === null,
        ),
      );
      return groupsWithNewQuestions;
    }
    //continue with all the questions
    return profileQuestions;
  }, [kitIdForReview, profileQuestions]);

  const profileAnswerMutation = useUpdateProfileAnswer();
  const mutationCallback = useCallback(
    (answers: ProfileQuestionAnswer[]) => {
      return profileAnswerMutation.mutateAsync({ answers, tinyAccountId });
    },
    [profileAnswerMutation],
  );

  useEffect(() => {
    //review flow without any new question will go through to the confirm page
    if (
      kitIdForReview &&
      profileQuestions?.length &&
      !profileQuestionForFlow?.length
    ) {
      //we give a "from state" to the starting point if the user wants to go back
      if (isHomeScreenEnabled) {
        navigate(`/profile/${kitIdForReview}/confirm`, {
          state: { from: `/information/${kitIdForReview}` },
        });
      } else {
        navigate(`/profile/${kitIdForReview}/confirm`, {
          state: { from: `/results/${kitIdForReview}` },
        });
      }
    }
  }, [profileQuestionForFlow]);

  useEffect(() => {
    if (initialLoad) {
      const indexUnansweredQuestion = profileQuestionForFlow?.findIndex(
        questionGroup => !questionGroup?.questions[0]?.answer_text,
      );
      if (
        indexUnansweredQuestion !== undefined &&
        indexUnansweredQuestion >= 0
      ) {
        setInitialLoad(false);
        //decide which modal to show based on the starting question index (start or resume)
        if (indexUnansweredQuestion > 0) {
          setShowProfileResumeModal(true);
        } else {
          setShowProfileStartModal(true);
        }
      }
    }
  }, [profileQuestionForFlow]);

  if (!tinyAccountId) return <></>;
  return (
    <>
      <Modal controlledIsOpen={showProfileStartModal} showHeader={false}>
        <ModalBody
          image={profileStartImage}
          title={`Let’s create your Tiny Profile`}
          description={`This can be for you or another family member that you’re planning to sample with Tiny Health. We’ll ask you a few questions about your health that will help us interpret your results.`}
          onFinish={() => setShowProfileStartModal(false)}
        />
      </Modal>
      <Modal controlledIsOpen={showProfileResumeModal} showHeader={false}>
        <ModalBody
          image={profileResumeImage}
          title={`Let’s finish your profile`}
          description={`Pick up where you left off. Tell us more about you to best interpret your results.`}
          onFinish={() => setShowProfileResumeModal(false)}
        />
      </Modal>
      <THSurvey
        tinyAccountId={tinyAccountId}
        questionGroups={profileQuestionForFlow}
        mutationCallback={mutationCallback}
      />
    </>
  );
};

const SampleInfoFlow = ({
  tinyAccountId,
  kitId,
}: {
  tinyAccountId: string;
  kitId: string;
}) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [showSampleInfoStartModal, setShowSampleInfoStartModal] =
    useState(false);
  const [showSampleInfoResumeModal, setShowSampleInfoResumeModal] =
    useState(false);
  const { data: sampleInfoQuestions } = useSampleInfoQuestions(
    tinyAccountId,
    kitId,
  );

  const sampleInfoAnswerMutation = useUpdateSampleInfoAnswer();
  const mutationCallback = useCallback(
    (answers: ProfileQuestionAnswer[]) => {
      return sampleInfoAnswerMutation.mutateAsync({
        answers,
        tinyAccountId,
        kitId,
      });
    },
    [sampleInfoAnswerMutation],
  );

  useEffect(() => {
    if (initialLoad) {
      const indexUnansweredQuestion = sampleInfoQuestions?.findIndex(
        questionGroup => !questionGroup?.questions[0]?.answer_text,
      );
      if (
        indexUnansweredQuestion !== undefined &&
        indexUnansweredQuestion >= 0
      ) {
        setInitialLoad(false);
        //decide which modal to show based on the starting question index (start or resume)
        if (indexUnansweredQuestion > 0) {
          setShowSampleInfoResumeModal(true);
        } else {
          setShowSampleInfoStartModal(true);
        }
      }
    }
  }, [sampleInfoQuestions]);

  if (!tinyAccountId || !kitId) return <></>;
  return (
    <>
      <Modal controlledIsOpen={showSampleInfoStartModal} showHeader={false}>
        <ModalBody
          image={sampleInfoStartImage}
          title={`Let’s complete your sample information`}
          description={`We’ll ask you a few questions about the sample user’s health. We can’t publish your results without it.`}
          onFinish={() => setShowSampleInfoStartModal(false)}
        />
      </Modal>
      <Modal controlledIsOpen={showSampleInfoResumeModal} showHeader={false}>
        <ModalBody
          image={profileResumeImage}
          title={`Let’s finish your sample information`}
          description={`Pick up where you left off. Tell us more about the sample user’s current health. We can’t publish your results without it.`}
          onFinish={() => setShowSampleInfoResumeModal(false)}
        />
      </Modal>
      <THSurvey
        tinyAccountId={tinyAccountId}
        questionGroups={sampleInfoQuestions}
        mutationCallback={mutationCallback}
      />
    </>
  );
};

export const THSurvey = ({
  tinyAccountId,
  questionGroups,
  mutationCallback,
  isSingleEdit = false,
}: {
  tinyAccountId: string;
  questionGroups?: ProfileQuestionsGroup[] | null;
  mutationCallback?: (answers: ProfileQuestionAnswer[]) => Promise<void>;
  isSingleEdit?: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { isFeatureEnabled: isHomeScreenEnabled } = useFeature('home_screen');
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const [initialLoad, setInitialLoad] = useState(true);
  const [startingQuestionIdx, setStartingQuestionIdx] = useState(0);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const kitIdForReview = useUrlQuery().get('review');
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ProfileFormData>();

  const onSubmit: SubmitHandler<ProfileFormData> = async formData => {
    const updatedAnswers = Object.keys(formData).reduce(
      (acc: ProfileQuestionAnswer[], key: string) => {
        const question_id = Number(key);
        const answer_text = formData[key];
        const currentQuestion = currentPage?.questions.find(
          question => question.id === question_id,
        );
        if (currentQuestion) {
          const previousAnswer = currentQuestion.answer_text;
          if (!areTheAnswersEqual(previousAnswer, answer_text)) {
            //only add the question if the answer has changed
            return [...acc, { question_id, answer_text }];
          }
        }
        //skip the question if the answer hasn't changed
        return acc;
      },
      [],
    );

    if (mutationCallback) {
      try {
        if (updatedAnswers?.length) {
          await mutationCallback(updatedAnswers);
        }
        if (questionGroups?.length) {
          if (currentQuestionIdx < questionGroups.length - 1) {
            //move to the next screen
            setCurrentQuestionIdx(currentQuestionIdx + 1);
          } else {
            //finish the survey and redirect
            if (kitIdForReview) {
              //if this is a review, redirect to the confirmation page to check the previously answered questions
              navigate(`/profile/${kitIdForReview}/confirm`);
            } else if (isSingleEdit) {
              //return to the confirmation page listing the questions
              navigate(
                state?.from ? state.from : `/profile/${currentKitId}/confirm`,
              );
            } else if (state?.from) {
              navigate(state.from);
            } else if (isHomeScreenEnabled) {
              dispatch(setHomePageTinyAccountId(tinyAccountId));
              navigate('/');
            } else {
              navigate(`/kits`);
            }
            toast.success('Your answers have been saved');
          }
        }
      } catch (error) {
        toast.error('Something went wrong, please try again');
      }
    }
  };

  useEffect(() => {
    if (initialLoad) {
      const indexUnansweredQuestion = questionGroups?.findIndex(
        questionGroup => !questionGroup?.questions[0]?.answer_text,
      );
      if (
        indexUnansweredQuestion !== undefined &&
        indexUnansweredQuestion >= 0
      ) {
        setInitialLoad(false);
        setStartingQuestionIdx(indexUnansweredQuestion);
        setCurrentQuestionIdx(indexUnansweredQuestion);
      }
    }
  }, [questionGroups]);

  const currentPage = useMemo(
    () =>
      questionGroups?.length && questionGroups[currentQuestionIdx]
        ? questionGroups[currentQuestionIdx]
        : null,
    [questionGroups, currentQuestionIdx],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [questionGroups]);

  if (!questionGroups || !currentPage) return <></>;
  return (
    <>
      {isSingleEdit ? (
        <Header title='Edit your answers' />
      ) : (
        <ProfileHeader
          currentQuestionIdx={currentQuestionIdx}
          questions={questionGroups}
          goBack={() => {
            setCurrentQuestionIdx(current => current - 1);
          }}
        />
      )}
      <PageContainer
        size={ContainerSizes.SM}
        className={styles.profileContainer}
      >
        <div className={styles.infoContainer}>
          {!!currentPage.title && (
            <Typography variant='heading-l'>{currentPage.title}</Typography>
          )}
          {!!currentPage.subtitle && (
            <Typography variant='body-s' serif className='grey-text'>
              {currentPage.subtitle}
            </Typography>
          )}

          <form
            className={styles.formContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            {currentPage.questions.map(question => (
              <ProfileQuestionItem
                key={question.id}
                question={question}
                register={register}
                errors={errors}
                control={control}
              />
            ))}
            <div className={styles.buttons}>
              <DesignButton
                label='Continue'
                type='submit'
                loading={isSubmitting}
                size={ButtonSizes.M}
                color={ButtonColors.PURPLE}
                width={'full'}
                className='mt-2'
              />
              {!isSingleEdit && (
                <div className='flex flex-column w-100 text-center'>
                  <LinkButton
                    label='Finish later'
                    iconNameRight='closeSm'
                    className='mt-2'
                    href={state?.from ? state.from : `/kits`}
                    variant={ButtonVariants.TEXT}
                    size={ButtonSizes.M}
                    color={ButtonColors.PURPLE}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </PageContainer>
    </>
  );
};

export default Profile;
